import React from "react";
import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import ContactUs from "./pages/ContactUs";

function App() {
  const routes = createRoutesFromElements(
    <>
      <Route path="/" element={<HomePage />} />
      <Route path="/contact" element={<ContactUs />} />
    </>
  );
  const router = createBrowserRouter(routes);

  return (
    <div className="website-container">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
