// import React from "react";
// import "../../styles/Component.css";

// import "./Component4.css";
// import { styles } from "../../style";

// const Comp4 = () => {
//   return (
//     <div className="trusted-by-component" id="trusted-by-component">
//       {/* <div className="bg-[#bfe3fb] ">
//         <p className={` ${styles.sectionSubText}`}>Trusted By</p>
//       </div> */}
//       <div className="flex justify-center items-center">
//         <div className="bg-[#bfe3fb] w-4/6 rounded-lg ">
//           <p className={` ${styles.sectionSubText} `}>Trusted By </p>
//         </div>
//       </div>

//       <div className="customer-slider">
//         <div className="image-container">
//           <img
//             src={require("../../assets/images/angel-one-logo.png")}
//             alt="angel-one-logo"
//           />
//           <img
//             src={require("../../assets/images/liva-logo.png")}
//             alt="liva-logo"
//           />
//           <img
//             src={require("../../assets/images/reliance-logo.png")}
//             alt="reliance-trends-logo"
//           />
//           <img
//             src={require("../../assets/images/philips-logo.png")}
//             alt="philips-logo"
//           />
//           <img
//             src={require("../../assets/images/cop-28-logo.png")}
//             alt="cop-28-logo"
//           />
//         </div>
//         <div className="image-container">
//           <img
//             src={require("../../assets/images/uniqlo-logo.png")}
//             alt="uniqlo-logo"
//           />
//           <img
//             src={require("../../assets/images/zoomcar-logo.png")}
//             alt="zoomcar-logo"
//           />
//           <img
//             src={require("../../assets/images/tasva-logo.png")}
//             className=" h-[34000px] w-[34000px] "
//             alt="tasva-logo"
//           />
//           <img
//             src={require("../../assets/images/blibli-logo.png")}
//             alt="blibli-logo"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Comp4;

import React, { useState } from "react";
import "../../styles/Component.css";
import "./Component4.css";
import { styles } from "../../style";
import { motion } from "framer-motion";

const Comp4 = () => {
  // Dummy data for logos
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  const logos = [
    {
      src: require("../../assets/images/angel-one-logo.png"),
      alt: "angel-one-logo",
    },
    {
      src: require("../../assets/images/liva-logo.png"),
      alt: "liva-logo",
    },
    {
      src: require("../../assets/images/reliance-logo.png"),
      alt: "reliance-trends-logo",
    },
    {
      src: require("../../assets/images/philips-logo.png"),
      alt: "philips-logo",
    },
    {
      src: require("../../assets/images/cop-28-logo.png"),
      alt: "cop-28-logo",
    },
    {
      src: require("../../assets/images/uniqlo-logo.png"),
      alt: "uniqlo-logo",
    },
    {
      src: require("../../assets/images/zoomcar-logo.png"),
      alt: "zoomcar-logo",
    },
    {
      src: require("../../assets/images/tasva-logo.png"),
      alt: "tasva-logo",
    },
    {
      src: require("../../assets/images/blibli-logo.png"),
      alt: "blibli-logo",
    },
  ];
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);

  var itemsPerPage = 5;

  if (isMobile) {
    itemsPerPage = 2;
  }

  const totalPages = Math.ceil(logos.length / itemsPerPage);

  // Function to handle pagination
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Calculate indexes for pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div
      className="trusted-by-component mb-2 text-lg font-semibold"
      id="trusted-by-component"
    >
      <div className="heading">
        <div className="line" />
        <h2 className="font-['Helvetica'] text-center"> We Are Trusted By </h2>
        <div className="line" />
      </div>

      <div className="customer-slider">
        <div className="image-container">
          {logos.slice(startIndex, endIndex).map((logo, index) => (
            <motion.img
              key={index}
              src={logo.src}
              alt={logo.alt}
              className="logo-item"
              whileHover={{ scale: 1.3 }}
              // style={{ width: "150px", margin: "0 10px" }} // Adjust logo size and margin
            />
          ))}
        </div>
      </div>

      {/* Pagination controls */}
      {totalPages > 1 && (
        <div className="pagination-container mt-4">
          {" "}
          {/* Add margin top */}
          <div className="pagination-card">
            <button
              className="pagination-btn font-['Helvetica'] text-sm  font-normal"
              disabled={currentPage === 1}
              onClick={handlePrevPage}
            >
              &#8592; Prev {/* Left arrow */}
            </button>
            <span className="mx-4 font-['Helvetica'] text-sm font-thin">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="pagination-btn font-['Helvetica'] text-sm  font-normal"
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              Next &#8594; {/* Right arrow */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Comp4;
