import React from "react";
import { motion } from "framer-motion";
import "../../styles/Component.css";
import "./Component2.css";

const Comp2 = () => {
  return (
    <div id="about" className="about-container ">
      <div className="about-image  flex items-center justify-center">
        <img src={require("../../assets/images/about_img.png")} alt="" />
      </div>

      <div className="about-text">
        <h2 className="font-['Helvetica'] mb-2  font-semibold">
          Who Are We......
        </h2>
        <motion.div
          className="card bg-white rounded-lg p-4 mr-4 mb-4 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.1 }}
        >
          <p className="text-[10px] font-medium">
            <span className="text-[#00b7ff] ">AdLantic Media Network</span> is
            an in-house delivering network that provides the most efficient
            advertisement services in the market.
          </p>
        </motion.div>
        <motion.div
          className="card bg-white rounded-lg p-4 mr-4 mb-4 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.1 }}
        >
          <p className="text-[10px] font-medium">
            <span className="text-[#00b7ff] "> A branding beast, </span>that is
            what they call us because we have an experience of more than 4 years
            in delivering brand awareness campaigns across the globe.
          </p>
        </motion.div>
        <motion.div
          className="card bg-white rounded-lg p-4 mb-4 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.1 }}
        >
          <p className="text-[10px] font-medium">
            <span className="text-[#00b7ff] ">Branding </span>is our
            obsession.Our super creative and skillful branding teams are capable
            of turning your ideas into brands.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default Comp2;
