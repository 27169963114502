import React from "react";
import "../../styles/Component.css";
import "./Footer.css";
import phoneicon from "../../assets/images/phone-icon.svg";
import emailicon from "../../assets/images/email-icon.svg";
import instagramicon from "../../assets/images/instagram-icon.svg";
import skypeicon from "../../assets/images/skype-icon.svg";
import linkedinicon from "../../assets/images/linkedin-icon.svg";

const Footer = () => {
  const isDesktop = window.innerWidth >= 768;

  return (
    <div className="footer">
      <div className="bottom-container">
        <div className="columns">
          <div className="column adlantic-media">
            <h3 className="font-['Poppins'] ">AdLantic media</h3>
            <p className="font-['Poppins'] font-extralight  text-sm mt-5 ">
              We offer a range of services to meet your business needs,
              including effective marketing strategies, SMS marketing solutions,
              and professional web development services.
            </p>
          </div>

          <div className="column our-services">
            <h3 className="font-['Poppins'] ">Our Services</h3>
            <ul className="font-['Poppins'] font-extralight text-sm mt-3">
              <li>Branding</li>
              <li>Social Media Marketing</li>
              <li>Influencer Marketing</li>
              <li>Youtube Campaigns</li>
              <li>Whatsapp Campaigns</li>
              <li>Performance Marketing</li>
            </ul>
          </div>

          <div className="column contact-information">
            <h3 className="font-['Poppins']">Contact Information</h3>
            <ul>
              <li>
                <div className="address">
                  <div className="address-data font-['Poppins'] font-extralight text-sm mt-5">
                    Address - 3rd Floor, Landmark Cyber Park, Gurugram, Haryana
                    122002
                  </div>
                </div>
              </li>
              <li>
                <div className="email">
                  <div className="email-content font-['Poppins'] font-extralight  text-sm mt-5 ">
                    <a href="mailto:Business@adlantic.in">
                      Mail Id : Business@adlantic.in
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Follow Us Section with line */}
        <div className="follow-us-section mt-4">
          {" "}
          {/* Add margin top */}
          <div className="follow-us-line-wrapper">
            <hr className="follow-us-line" />
            <h3 className="text-center font-bold font-['Poppins']">
              Connect With Us
            </h3>
            <hr className="follow-us-line" />
          </div>
          <div className="font-['Poppins'] contact-icons flex justify-center">
            <a href="tel:+918109369919" className="flex flex-col items-center">
              <img src={phoneicon} alt="phone icon" className="icon" />
              {isDesktop && <span className="font-medium">Phone</span>}
            </a>
            <a
              href="skype:live:36949984a397b337?chat"
              className="flex flex-col items-center"
            >
              <img src={skypeicon} alt="skype icon" className="icon" />
              {isDesktop && <span className="font-medium">Skype</span>}
            </a>
            <a
              href="https://www.linkedin.com/company/adlanticmedia/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center"
            >
              <img src={linkedinicon} alt="linkedin icon" className="icon" />
              {isDesktop && <span className="font-medium">LinkedIn</span>}
            </a>
            <a
              href="https://www.instagram.com/adlantic.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center"
            >
              <img src={instagramicon} alt="instagram icon" className="icon" />
              {isDesktop && <span className="font-medium">Instagram</span>}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
