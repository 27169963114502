import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../../styles/Component.css";
import "./Component1.css";
import { logo, menu, close } from "../../assets";

const Comp1 = () => {
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  useEffect(() => {
    // Do nothing to keep the image static
  }, []);

  return (
    <div className="image-component mt-7 ">
      <div className="text-container text-black  w-[900px] text-left ml-8">
        <br />
        <h1 className="font-['Helvetica'] text-[20px] mt-[25px] text-[#00b7ff] ">
          Hello !
        </h1>
        <h2 className="font-['Helvetica'] text-[#011F4B] text-[14px] mb-[2px] ">
          We are AdLantic Media.
        </h2>
        <p id="DM" className="text-[5px]">
          Your premier digital marketing partner.
        </p>
        <Link to="/contact" className="text-white  ">
          <button
            id="button"
            className="bg-[#011F4B] hover:bg-[#F1FFFF] hover:text-[#011F4B] text-white text-[10px] hover:text-white  mt-2 py-1 px-[10px] rounded-sm"
            // Adjust padding and font size here
          >
            Contact Us
          </button>
        </Link>
      </div>
      <img
        src={require("../../assets/images/mobile_hero1.png")}
        alt="Mobile Graphic"
        className="mt-6 mr-8 mobile-graphic"
      />
    </div>
  );
};

export default Comp1;
