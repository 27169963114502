const styles = {
  paddingX: "sm:px-16 px-6",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-16 py-10",

  heroHeadText:
    "font-black font-outline-2 purple-text-gradient lg:text-[80px]  sm:text-[60px] xs:text-[50px] text-[40px] lg:leading-[98px] mt-2",
  heroSubText:
    "font-medium lg:text-[25px] sm:text-[20px] xs:text-[20px] text-[16px] lg:leading-[40px]",

  sectionHeadText:
    "purple-text-gradient font-black md:text-[60px]  sm:text-[50px] xs:text-[40px] text-[30px]",
  sectionSubText:
    "text-black sm:text-[20px] text-[14px] text-center py-[5px] font-['Poppins'] font-semibold tracking-wider",
};

export { styles };
