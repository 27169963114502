import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { styles } from "../../style";
import { navLinks } from "../constants";
import { logo, menu, close } from "../../assets";

const Header = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`${
        styles.paddingX
      }  rounded-lg  py-4 px-6 fixed top-0 left-0 right-0 z-50 ${
        scroll ? "bg-[#F1FFFF]" : "bg-transparent"
      }`}
    >
      <div className="w-full flex justify-between items-center max-w-7xl mx-auto">
        <Link
          to="/"
          className="flex items-center gap-2"
          onClick={() => {
            setActive("");
            window.scrollTo(0, 0);
          }}
        >
          <img
            src={logo}
            alt="logo"
            className="w-9 h-9 object-contain"
            style={{ width: "100px" }}
          />
        </Link>

        <ul className="list-none hidden sm:flex flex-row gap-10">
          {navLinks.map((link) => (
            <li
              key={link.id}
              className={`${
                active === link.title ? "text-[#00b7ff;]  " : "text-[#03001C] "
              }
                hover:text-[#6B7280] text-[17px] font-['Helvetica'] font-medium cursor-pointer`}
              onClick={() => setActive(link.title)}
            >
              <a
                href={
                  link.id === "/"
                    ? "/"
                    : link.id === "contact"
                    ? "/contact"
                    : `#${link.id}`
                }
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>

        <div className="sm:hidden flex justify-end items-center">
          <div
            className="w-[28px] h-[28px] bg-[#F1FFFF] flex justify-center items-center rounded-md" // Added background color
            onClick={() => setToggle(!toggle)}
          >
            <img
              src={toggle ? close : menu}
              alt="menu"
              className="w-[20px] h-[20px] object-contain"
            />
          </div>

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 bg-[#ffffff] bg-opacity-100 absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col gap-4">
              {navLinks.map((link) => (
                <li
                  key={link.id}
                  className={`${
                    active === link.title ? "text-[#219ebc] " : "text-[#03001C]"
                  }
                  font-['Helvetica'] text-[16px] font-medium cursor-pointer`}
                  onClick={() => {
                    setActive(link.title);
                    setToggle(!toggle);
                  }}
                >
                  <a
                    href={
                      link.id === "/"
                        ? "/"
                        : link.id === "contact"
                        ? "/contact"
                        : `#${link.id}`
                    }
                  >
                    {link.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
