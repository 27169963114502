// // Comp3.js
// import React from 'react';
// import '../../styles/Component.css';
// import './Component3.css'; // Create a new CSS file for Component 3 styles

// const Comp3 = () => {
//   return (
//     <div className="services-component" id="services">
//       <div className='services-heading'>
//         <h2>SERVICES PROVIDED</h2>
//       </div>
//       <div className="service-container">
//         {/* Service 1 */}
//         <div className="service">
//           <img src={require('../../assets/images/branding.png')} alt="Service Icon 1" />
//           <br></br>
//           <strong>Branding</strong>
//           <p>
//             Enhance your brand identity with our comprehensive branding solutions. We create unique and memorable brand experiences that resonate with your audience.
//           </p>
//         </div>

//         {/* Service 2 */}
//         <div className="service">
//           <img src={require('../../assets/images/social media.png')} alt="Service Icon 2" />
//           <br></br>
//           <strong>Social Media Marketing</strong>
//           <p>
//             Boost your online presence and engage with your target audience through our effective social media marketing strategies. Drive results and build a loyal community.
//           </p>
//         </div>

//         {/* Service 3 */}
//         <div className="service">
//           <img src={require('../../assets/images/influencer.png')} alt="Service Icon 3" />
//           <br></br>
//           <strong>Influencer Marketing</strong>
//           <p>
//             Leverage the influence of key personalities in your industry to reach a wider audience. Our influencer marketing campaigns drive authentic connections and brand awareness.
//           </p>
//         </div>

//          {/* Service 4 - YouTube Campaigns */}
//          <div className="service">
//           <img src={require('../../assets/images/YT marketing.png')} alt="Service Icon 4" />
//           <br></br>
//           <strong>YouTube Campaigns</strong>
//           <p>
//             Drive engagement and visibility on YouTube with our targeted video campaigns. Reach your audience effectively through captivating video content.
//           </p>
//         </div>

//         {/* Service 5 - WhatsApp Campaigns */}
//         <div className="service">
//           <img src={require('../../assets/images/whatsapp.png')} alt="Service Icon 5" />
//           <br></br>
//           <strong>WhatsApp Campaigns</strong>
//           <p>
//             Connect with your audience directly through WhatsApp campaigns. Deliver personalized messages and promotions to enhance customer engagement.
//           </p>
//         </div>

//          {/* Service 6 - Performance Marketing */}
//          <div className="service">
//           <img src={require('../../assets/images/marketing branding.png')} alt="Service Icon 5" />
//           <br></br>
//           <strong>Performance Marketing</strong>
//           <p>
//             Connect with your audience directly through WhatsApp campaigns. Deliver personalized messages and promotions to enhance customer engagement.
//           </p>
//         </div>

//         {/* Add more services as needed */}
//       </div>
//     </div>
//   );
// };

// export default Comp3;

import React from "react";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";

import { styles } from "../../style";
import { services } from "../constants";
import { SectionWrapper } from "../../hoc";
import { fadeIn } from "../../utils/motion";

const ServiceCard = ({ index, title, icon, content }) => {
  return (
    <Tilt className="xs:w-full w-[450px] ">
      <motion.div
        variants={fadeIn("right", "spring", 0.5 * index, 0.75)}
        initial="hidden"
        animate="show"
        className="w-full p-[1px] rounded-[20px] shadow-card border-[4px]"
      >
        <div className=" rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col ">
          <h3 className="text-[#00b7ff] font-['Helvetica'] text-[20px] font-normal text-center mb-4">
            {title}
          </h3>
          <motion.img
            src={icon}
            alt={title}
            className="object-contain h-40"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 * index }}
          />

          <motion.p
            className="text-[#000000] font-['Helvetica'] text-[15px] text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 * index + 0.2 }}
          >
            {content}
          </motion.p>
        </div>
      </motion.div>
    </Tilt>
  );
};

const Comp3 = () => {
  return (
    <>
      <div
        className="trusted-by-component mb-2 text-lg font-semibold"
        id="trusted-by-component"
      >
        <div className="heading  mb-4 font-['Helvetica']">
          <div className="line" />
          <h2 className="text-center">Services We Offer</h2>
          <div className="line" />
        </div>
      </div>
      {/* Service cards */}
      <div className="mt-14 flex flex-wrap gap-10 justify-center">
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {...service} />
        ))}
      </div>
    </>
  );
};

export default SectionWrapper(Comp3, "services");
