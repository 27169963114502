import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();
  const [successMessage, setSuccessMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_igp7f1o",
        "template_3z358sm",
        form.current,
        "6hFqF13FPgwqzyAD3"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          setSuccessMessage("Your message has been sent successfully!");
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000); // Hide success message after 5 seconds
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div>
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded-md mb-4">
          {successMessage}
        </div>
      )}
      <form
        ref={form}
        onSubmit={sendEmail}
        className=" max-w-[300px]  md:max-w-md mx-auto"
      >
        <div className="mb-4 mt-[140px] ">
          <label className="block mb-1" htmlFor="name">
            Name
          </label>
          <input
            className="w-full border border-gray-300 rounded-md py-2 px-3"
            type="text"
            name="name"
            id="name"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1" htmlFor="email">
            Email
          </label>
          <input
            className="w-full border border-gray-300 rounded-md py-2 px-3"
            type="email"
            name="email"
            id="email"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1" htmlFor="subject">
            Subject
          </label>
          <input
            className="w-full border border-gray-300 rounded-md py-2 px-3"
            type="text"
            name="subject"
            id="subject"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1" htmlFor="message">
            Message
          </label>
          <textarea
            className="w-full border border-gray-300 rounded-md py-2 px-3"
            name="message"
            id="message"
            rows="4"
          ></textarea>
        </div>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-md"
          type="submit"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
