import Comp1 from "../components/Comp1/Comp1";
import Comp3 from "../components/Comp3/Comp3";
import Comp2 from "../components/Comp2/Comp2";
import Comp4 from "../components/Comp4/Comp4";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import "../styles/Component.css";
import CompD from "../components/CompD/CompD"; // Update import statement
import React, { useEffect, useState } from "react";

const HomePage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  return (
    <div className="homepage-component">
      <Header />
      {isMobile ? <Comp1 /> : <CompD />}
      <Comp2 />
      <Comp3 />
      <Comp4 />
      <Footer />
    </div>
  );
};

export default HomePage;
