import {
  branding,
  yt,
  whatsapp,
  influencer,
  performance,
  smm,
} from "../../assets";

export const navLinks = [
  {
    id: "/",
    title: "Home",
  },
  {
    id: "about",
    title: "About",
  },
  {
    id: "services",
    title: "Services",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

export const services = [
  {
    title: "Branding",
    icon: branding,
    content:
      " Enhance your brand identity with our comprehensive branding solutions. We create unique and memorable brand experiences that resonate with your audience.",
  },
  {
    title: "Social Media Marketing",
    icon: smm,
    content:
      "Boost your online presence and engage with your target audience through our effective social media marketing strategies. Drive results and build a loyal community.",
  },
  {
    title: "Influencer Marketing",
    icon: influencer,
    content:
      "Leverage the influence of key personalities in your industry to reach a wider audience. Our influencer marketing campaigns drive authentic connections and brand awareness.",
  },
  {
    title: "YouTube Campaigns",
    icon: yt,
    content:
      "Drive engagement and visibility on YouTube with our targeted video campaigns. Capture your audience's attention with captivating video content that leaves a lasting impression.",
  },
  {
    title: "WhatsApp Campaigns",
    icon: whatsapp,
    content:
      "Connect with your audience directly through WhatsApp campaigns. Deliver personalized messages and promotions to enhance customer engagement.",
  },
  {
    title: "Performance Marketing",
    icon: performance,
    content:
      "Connect with your audience directly through WhatsApp campaigns. Deliver personalized messages and promotions to enhance customer engagement.",
  },
];
