
import Footer from "../components/Footer/Footer";
import ContactForm from "../components/ContactForm/ContactForm";
import Header from "../components/Header/Header";

const ContactUs = () => {
    return (
  
      <div className="contact-us-component">
        <Header/>
        <ContactForm/>
        <Footer/>
       </div>
  
    );
  };
  
  export default ContactUs;