import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"; // Import motion from Framer Motion
import "../../styles/Component.css";
import "./Componentd.css";

import { logo, menu, close } from "../../assets";

const CompD = () => {
  const [currentImageIndex] = useState(0);
  const images = [
    require("../../assets/images/heroo.png"),
    require("../../assets/images/heroo.png"),
    require("../../assets/images/heroo.png"),
  ];

  useEffect(() => {
    // Do nothing to keep the image static
  }, []);

  return (
    <div className="image-component">
      <div // Removed motion wrapper from div
        className="relative"
      >
        {/* Removed motion wrapper from img */}
        <img
          src={images[currentImageIndex]}
          alt={`Image ${currentImageIndex + 1}`}
          className="w-full h-auto rounded-lg"
        />
        <div
          className="absolute top-80 left-8 transform -translate-y-1/2"
          // Adjust top value here to position content higher
        >
          <div className="text-container text-black font-medium mr-6 w-[500px] text-left md:ml-4 ml-2">
            <br />
            <h1 className="font-['Helvetica'] text-4xl md:text-6xl mb-2 text-[#00b7ff]">
              Hello !
            </h1>
            <h2 className="font-['Helvetica'] text-4xl text-[#011F4B] md:text-2xl mb-2 ">
              We are AdLantic Media.
            </h2>
            <p>
              At Adlantic, we're not just another agency – we're your premier
              digital marketing partner. With creativity at our core and
              innovation in our DNA, we're here to propel your brand to new
              heights. Welcome to a world of limitless possibilities.
            </p>
            <Link to="/contact" className="text-white  ">
              <button
                className="font-Poppins bg-[#011F4B] hover:bg-[#F1FFFF] hover:text-[#011F4B] text-white text-[15px] text-2xl mt-4 py-1 px-4 rounded-lg"
                // Adjust padding and font size here
              >
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompD;
